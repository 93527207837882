import React from 'react';
import { Layout, Menu, Avatar, Dropdown } from 'antd';
import { UserOutlined, LogoutOutlined, MessageOutlined, BranchesOutlined, PicRightOutlined, WindowsOutlined, SettingOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
const { REACT_APP_BASE_API_URI } = process.env;
const { Header, Content, Sider } = Layout;

const DashboardLayout = ({ children, userName, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();  // Get the current location

  const handleClick = (e) => {
    if (e.key.includes("http")) {
      window.location.href = e.key;
    }
    else {
      navigate(e.key);
    }
  };

  const baseApiUri = process.env.REACT_APP_BASE_API_URI;

  const handleLogout = () => {
    navigate(`${baseApiUri}`);
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider breakpoint="lg" collapsible collapsedWidth="0" style={{ top: "0" }}>
        <div className="logo" style={{ fontSize: '15px', height: '32px', margin: '16px', color: '#fff' }}>First Outcomes</div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}  // Auto select based on current path
        >
          <Menu.Item key="/PossibleResponseGroup" icon={<MessageOutlined />} onClick={handleClick}>
            Possible Response
          </Menu.Item>
          <Menu.Item key="/MetaDataGroup" icon={<WindowsOutlined />} onClick={handleClick}>
            Metadata Group
          </Menu.Item>
          <Menu.Item key='/Configuration' icon={<SettingOutlined />} onClick={handleClick}>
          Configuration
          </Menu.Item>
          <Menu.Item key="/WorkFlowComponent" icon={<PicRightOutlined />} onClick={handleClick}>
            Component Builder
          </Menu.Item>
          <Menu.Item key='/WorkFlow' icon={<BranchesOutlined />} onClick={handleClick}>
            Workflow
          </Menu.Item>
          <Menu.Item key='/UploadWorkflow' icon={<BranchesOutlined />} onClick={handleClick}>
            Workflow Upload
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ marginLeft: '47px' }}>Admin Dashboard</div>
          <div style={{ marginRight: '16px' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar icon={<UserOutlined />} focus /> {userName}
              </span>
            </Dropdown>
          </div>
        </Header>
        <Content style={{ margin: '5px 0', overflow: 'initial' }}>
          <div style={{ padding: 5, background: '#fff', minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
