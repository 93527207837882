import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Table, Tag, Drawer, Select, Space, Input, InputNumber, Typography, Dropdown, Divider, Card, Col, Row, Modal, message, Tooltip, Checkbox, Flex, Mentions } from 'antd';
import { EditOutlined, DeleteOutlined, SettingOutlined, GroupOutlined, RollbackOutlined, LinkOutlined, FlagOutlined, PhoneOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import '../../../assets/styles/common.css';
import { passOutputToParent, isJsonString } from '../../../assets/js/common.js';
import { fetchBranchInitialData, UpdateWorkflowComponent } from '../../../services/componentService.js';

const { Option } = Select;
const { TextArea } = Input;
const CONST_MAPPING = {
    NEXT_WORKFLOW: "NEXTWORKFLOW",
    SAME_COMPONENT: "SAMECOMPONENT",
    PROCESS_TYPE_API: 'API CONFIG',
    PROCESS_TYPE_SP: 'SP CALL',
    PROCESS_TYPE_MESSAGE: 'MESSAGE BUILD',
    OPERATOR_WORKFLOW_ID: 2,
    OPERATOR_LINE_TEXT: 'operator',
    MULTISELECT_MAX_DISPLAY: 2,
}

const BranchComponent = () => {

    //Config Section
    const [formMessage] = Form.useForm();
    const [processType, setProcessType] = useState("0");
    const [entries, setEntries] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentEntryIndex, setCurrentEntryIndex] = useState(null);
    const [functionType, setFunctionType] = useState('0');
    const [functionNameId, setFunctionName] = useState('0');
    const [messageTextArea, setTextAreaValue] = useState('');
    const [spId, setName] = useState('0');
    const [nodeKey, setNodeKey] = useState("");
    const [drawerTitle, setDrawerTitle] = useState('Edit Config');
    const [functionTypes, setFunctionTypes] = useState([]);
    const [functionNames, setFunctionNames] = useState([]);
    const [listSp, setListSp] = useState([]);
    const [messageIndexes, setMessageIndexes] = useState([]);
    const [workflows, setWorkflows] = useState([]);
    const [isUserRestricted, setIsUserRestricted] = useState(false);
    var AdminRoleId = "2";
    //Message Build
    const [messageTitle, setmessageTitle] = useState('');
    const [messageBuildData, setMessageBuildData] = useState([]);
    const [msgBuildEditingItem, setMsgBuildEditingItem] = useState(null);
    const [selectedDDParameters, setSelectedDDParameters] = useState([]);
    const [isMsgBuildEditing, setMsgBuildIsEditing] = useState(false);
    const [messageType, setMessageType] = useState('Voice');

    //possible Response
    const [toggleResponseDrawer, setToggleResponseDrawer] = useState(false);
    const [PossibleResponseGroups, setPossibleResponseDropdown] = useState([]);
    const [selectedPossibleResponse, setPossibleResponseSelectedItems] = useState([]);
    const [possibleResponseData, setpossibleResponseData] = useState([]);
    const [priorityNumber, setPriorityMaxNumber] = useState(30);
    const [isResponseEditing, setIsResponseEditing] = useState(false);
    const [editResponseItem, setResponseEditingItem] = useState(null);
    const [resGroupText, setResponseGroupText] = useState('');
    const generateIntegerOptions = (max) => {
        let options = [];
        for (let i = 0; i <= max; i++) {
            options.push(i);
        }
        return options;
    };
    const columnsResponseGroup = [
        { title: 'ID', dataIndex: 'responseGroupId', key: 'responseGroupId', hidden: true, width: 100, },
        { title: 'Keyword', dataIndex: 'keywordTextId', key: 'keywordTextId', width: 100, hidden: true },
        { title: 'Keyword', dataIndex: 'keywordText', key: 'keywordText', width: 100 },
        { title: 'Priority', dataIndex: 'keywordPriority', key: 'keywordPriority', width: 100 },
        {
            title: 'Actions', width: 60, key: 'actions', render: (text, record) =>
            (<> <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => editResponseGroupItem(record)}> <EditOutlined className='text-color-black' /> </Button>
                <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} danger onClick={() => deleteResponseGroupItem(record.responseGroupId)}> <DeleteOutlined /> </Button> </>),
        },
    ];

    //message Parameters add when press '@'
    const textareaRef = useRef(null);
    const [showMessageIndex, setMessageIndexDropdown] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [messageCursorSelection, setCursorSelection] = useState();
    // const [selectedParameters, setSelectedParameters] = useState([]);
    const [selectedParametersText, setSelectedParametersText] = useState([]);

    //Line section
    const [form] = Form.useForm();
    const [lineType, setLineType] = useState('');
    const [showWorkflowDropdown, setShowWorkflowDropdown] = useState(false);
    const [lineNextFlow, setWorkflow] = useState('0');
    const [tableData, setTableData] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [lineFlowText, setLineFlowText] = useState('');
    const columnsLine = [
        { title: 'Type', dataIndex: 'lineType', key: 'lineType', },
        { title: 'Text', dataIndex: 'lineText', key: 'lineText' },
        { title: 'Workflow', dataIndex: 'lineNextFlowText', key: 'lineNextFlowText' },
        { title: 'Workflow', dataIndex: 'lineNextFlow', key: 'lineNextFlow', hidden: true },
        {
            title: 'Action', key: 'action',
            render: (text, record) => (
                <Space>
                    <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => handleLineEdit(record)}><EditOutlined /></Button>
                    <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => handleLineDelete(record.key)}><DeleteOutlined /></Button>
                </Space>
            ),
        },
    ];

    //Fallback section    
    const [toggleFallbackDrawer, setToggleFallbackDrawer] = useState(false);
    const [fallbackData, setFallbackData] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [fallbackTypeText, setfallbackTypeText] = useState('');
    const fallbackTextareaRef = useRef(null);
    const [fallbackTextMessage, setFallbackTextMessage] = useState('');
    const [selectedFallBackParametersText, setselectedFallBackParametersText] = useState([]);
    const [showFallbackMessageIndex, setFallbackMessageIndexDropdown] = useState(false);
    const [fallbackfilteredSuggestions, setFallbackFilteredSuggestions] = useState([]);
    const [fallbackMsgCursorSelection, setFallbackCursorSelection] = useState();

    //Node state
    const [componentEndCall, setComponentEndCall] = useState(false);
    const [componentEndOperator, setComponentEndOperator] = useState(false);
    const [pauseRecord, setpauseRecord] = useState(false);

    //Call config
    const [incomingResponseTypeData, setIncomingResponseTypeData] = useState([]);
    const [speechTimeoutNum, setspeechTimeout] = useState(0);
    const [ivrCharLimit, setIvrCharLimit] = useState(0);
    const [incomingResponseType, setIncomingResponseType] = useState([]);
    const [masterConfig, setMasterConfig] = useState(null);
    //#region Useeffect
    useEffect(() => {
        // Check the roleId from session storage
        const roleId = sessionStorage.getItem('roleId');
        if (roleId === AdminRoleId) {
            setIsUserRestricted(true);
        }

        //Load intial data to fields
        async function fetchPopulateData() {
            try {
                const response = await fetchBranchInitialData();
                if (response.data !== undefined) {
                    setFunctionTypes(response.data.functionType);
                    setFunctionNames(response.data.functionName);
                    //setMessageIndexes(response.messageIndexData.map(item => item.messageIndexData));
                    setMessageIndexes(response.data.messageIndexData);
                    setListSp(response.data.spList);
                    setWorkflows(response.data.workFlows);
                    setPossibleResponseDropdown(response.data.possibleResponseGroup);
                    setIncomingResponseTypeData(response.data.incomingResponseType);
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
            }
        }
        fetchPopulateData();
    }, []);

    useEffect(() => {
        // Add event listener to listen for messages from the parent window
        async function fetchComponentData() {
            try {
                window.addEventListener('message', compDataLoad);
                return () => {
                    window.removeEventListener('message', compDataLoad);
                };
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
            }
        }
        fetchComponentData();
        componentBuilderLoad();
    }, []);
    //#endregion    

    /*
    ------First load to show/hide setting screen based on master and user-----
    ------Load Master config Data from 'Component Builder'------------
*/
    const componentBuilderLoad = () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if (urlParams != null && urlParams != "") {
            const keyFromQuery = urlParams.get('config');
            const compId = urlParams.get('compId');
            if (keyFromQuery != null) {
                if (keyFromQuery.toLocaleLowerCase() === 'master') {
                    setMasterConfig(keyFromQuery);
                }
                if (compId != null && compId != "" && compId != undefined && compId != "null") {
                    fetchMasterConfiguredData(compId, null);
                }
            }
        }
        else { console.log("Component opened in workflow"); }
    }

    const fetchMasterConfiguredData = async (compKeyId) => {
        try {
            if (compKeyId && compKeyId !== null) {
                const response = await UpdateWorkflowComponent(compKeyId);

                if (response.hasOwnProperty("component")) {
                    const compJson = JSON.parse(response.component.config);

                    if (compJson) {
                        const processedData = JSON.parse(compJson.loadJson);

                        if (processedData) {
                            // Load config data
                            setEntries(processedData.configData.config || []);
                            setpossibleResponseData(processedData.configData.possibleResponse || []);
                            setFallbackData(processedData.configData.fallbackMessage || []);
                            setComponentEndCall(!!processedData.configData.endCall);
                            setComponentEndOperator(!!processedData.configData.connectOperator);
                            setpauseRecord(!!processedData.configData.pauseRecording);
                            setMessageType(processedData.configData.communicationType || 'Voice');

                            // Load call configs
                            setspeechTimeout(processedData.configData.speechTimeout || 0);
                            setIvrCharLimit(processedData.configData.ivrCharacterLimit || 0);
                            setIncomingResponseType(processedData.configData.incomingTextType || []);
                            if (processedData.configLines) {
                                // Remove default lines and map the data for the table
                                const processedLines = processedData.configLines.lines.filter(line => line.key !== -1)
                                    .map(item => ({ ...item, lineText: item.lineText.split('|')[0], }));
                                setTableData(processedLines || []);
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching fetchMasterConfiguredData:', error);
        }
    };


    //#region Load Component Data From Parent Window
    const compDataLoad = (event) => {
        if (event.source === window.parent) {
            if (event.data != null && event.data != "" && event.data != undefined) {
                //Unique node 'KEY' Get from parent and set output json to appropriate Component
                setNodeKey(event.data.key);
                const incomingData = event.data;
                if (isJsonString(incomingData.data)) {
                    const loadData = JSON.parse(incomingData.data);
                    if (loadData.loadJson != null && loadData.loadJson != undefined) {
                        var processData = loadData.loadJson;
                        if (typeof loadData.loadJson !== 'object') {
                            processData = JSON.parse(loadData.loadJson);
                        }
                        //const processData = { "configData": { "config": [{ "processId": 1, "processType": "API Config", "data": { "functionType": "Dialog Flow", "functionNameId": 5 } }], "fallbackMessage": [{ "fallbackId": 1, "fallbackTypeText": "Second", "fallbackType": "2", "fallbackMessage": "Fallback 2" }], "possibleResponse": [1, 2, 3], "endCall": 0, "connectOperator": 1,"pauseRecording": 1, "speechTimeout": 200, "ivrCharacterLimit": 23 }, "configLines": { "lines": [{ "key": 1, "lineType": "nextcomponent", "lineNextFlow": 0, "lineText": "YES" }, { "key": 2, "lineType": "nextworkflow", "lineNextFlow": 9, "lineText": "FLOW|9" }, { "key": 3, "lineType": "samecomponent", "lineNextFlow": 0, "lineText": "SAMEE|SAMECOMPONENT" }, { "key": -1, "lineType": "nextworkflow", "lineNextFlow": 2, "lineText": "operator|2" }] } };
                        if (processData != null && processData != "") {
                            if (processData.configData) {
                                // Load data for Configuration section
                                setEntries(processData.configData.config || []);
                                // Load data for Possible Response Group                                           
                                //setPossibleResponseSelectedItems(processData.configData.possibleResponse || []);
                                setpossibleResponseData(processData.configData.possibleResponse || []);
                                // Load data for Fallback messages
                                setFallbackData(processData.configData.fallbackMessage || []);
                                // Load data for Component End stage
                                setComponentEndCall(processData.configData.endCall == 1 ? true : false || false)
                                setComponentEndOperator(processData.configData.connectOperator == 1 ? true : false || false)
                                setpauseRecord(processData.configData.pauseRecording == 1 ? true : false || false)
                                setMessageType(processData.configData.communicationType || 'Voice')

                                //Load call configs
                                setspeechTimeout(processData.configData.speechTimeout || 0)
                                setIvrCharLimit(processData.configData.ivrCharacterLimit || 0)
                                setIncomingResponseType(processData.configData.incomingTextType || [])
                            }
                            // Load data for Line section
                            if (processData.configLines) {
                                //Remove Default Lines and Bind to table
                                processData.configLines.lines = processData.configLines.lines.filter(line => line.key !== -1);

                                const processedLines = processData.configLines.lines.map(item => ({ ...item, lineText: item.lineText.split('|')[0] }));
                                setTableData(processedLines || []);
                            }
                        }
                    }
                }
            }
        }
    };
    //#endregion

    //#region Line Section
    const handleLineTypeChange = value => {
        setLineType(value);
        form.setFieldsValue({ lineNextFlow: 0 });
        setShowWorkflowDropdown(value.toUpperCase() === CONST_MAPPING.NEXT_WORKFLOW);
    };

    // const handleWorkflowChange = value => {
    //     setWorkflow(value);
    // };

    const handleWorkflowChange = (value, option) => {
        setWorkflow(value);
        setLineFlowText(option.children);
    };

    const handleLineAdd = async () => {
        await form.validateFields(['lineType', 'lineNextFlow', 'lineText']).then(values => {
            const newData = {
                //key: tableData.length + 1,
                key: tableData.length ? tableData[tableData.length - 1].key + 1 : 1,
                lineType,
                lineNextFlow,
                lineNextFlowText: lineFlowText,
                ...values,
            };
            setTableData([...tableData, newData]);
            form.resetFields();
            setLineFlowText('');
            setShowWorkflowDropdown(false);
        }).catch(errorInfo => {
            console.log('Validation failed:', errorInfo);
        });
    };

    const handleLineDelete = key => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                const newData = tableData.filter(item => item.key !== key);
                setTableData(newData);
            },
            onCancel() {
            },
        });
    };

    const handleLineEdit = record => {
        form.setFieldsValue(record);
        setEditingKey(record.key);
        setShowWorkflowDropdown(record.lineType.toUpperCase() === CONST_MAPPING.NEXT_WORKFLOW);
    };

    const handleLineUpdate = async () => {
        try {
            await form.validateFields(['lineType', 'lineNextFlow', 'lineText']).then(values => {
                const updatedData = tableData.map(item => {
                    if (item.key === editingKey) {
                        return {
                            ...item,
                            lineNextFlowText: lineFlowText,
                            ...values,
                        };
                    }
                    return item;
                });
                setTableData(updatedData);
                form.resetFields();
                setEditingKey('');
                setLineFlowText('');
                setShowWorkflowDropdown(false);
            });
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };
    //#endregion

    //#region Messageparameters 
    //Message textarea show index dropdown after type'@'
    const handleSuggestionClick = (suggestion) => {
        if (textareaRef.current) {
            const cursorPosition = messageCursorSelection;
            const textBeforeCursor = messageTextArea.slice(0, cursorPosition);
            const textAfterCursor = messageTextArea.slice(cursorPosition);
            const lastSpaceIndex = textBeforeCursor.lastIndexOf(' ');
            const trimmedSuggestion = suggestion.trim();
            const newText =
                textBeforeCursor.slice(0, lastSpaceIndex) +
                ` [${trimmedSuggestion}]` +
                textAfterCursor;

            setTextAreaValue(newText);
            setMessageIndexDropdown(false);
            textareaRef.current.focus();
            formMessage.setFieldsValue({ messageTextArea: newText });

            setCursorSelection(lastSpaceIndex + trimmedSuggestion.length + 2); // +2 for the brackets
        }
    };
    const handleMessageInputChange = (e) => {
        const value = e.target.value.replace(/@/g, ' ');
        setTextAreaValue(value);
        const cursorPosition = e.target.selectionStart;
        setCursorSelection(cursorPosition);
        const textBeforeCursor = value.slice(0, cursorPosition);
        const lastSpaceIndex = textBeforeCursor.lastIndexOf(' ');
        if (lastSpaceIndex !== -1) {
            const query = textBeforeCursor.slice(lastSpaceIndex + 1);
            const filtered = selectedParametersText.filter((suggestion) =>
                suggestion.startsWith(query)
            );
            setFilteredSuggestions(filtered);
            setMessageIndexDropdown(filtered.length > 0);
        } else {
            setMessageIndexDropdown(false);
        }
        setCursorSelection(cursorPosition);
    };

    const handleFallbackSuggestionClick = (suggestion) => {
        if (fallbackTextareaRef.current) {
            const cursorPosition = fallbackMsgCursorSelection;
            const textBeforeCursor = fallbackTextMessage.slice(0, cursorPosition);
            const textAfterCursor = fallbackTextMessage.slice(cursorPosition);
            const lastAtSymbolIndex = textBeforeCursor.lastIndexOf(' ');

            // Wrap the suggestion in square brackets without whitespace
            const newText =
                textBeforeCursor.slice(0, lastAtSymbolIndex) +
                ` [${suggestion.trim()}]` +
                textAfterCursor;

            setFallbackTextMessage(newText);
            setFallbackMessageIndexDropdown(false);
            fallbackTextareaRef.current.focus();
            form.setFieldsValue({ fallbackMessage: newText });
            setFallbackCursorSelection(cursorPosition + suggestion.length + 2);
        }
    };

    const handleFallbackMessageInputChange = (e) => {
        const value = e.target.value.replace(/@/g, ' ');
        setFallbackTextMessage(value);
        const cursorPosition = e.target.selectionStart;
        setFallbackCursorSelection(cursorPosition);

        const textBeforeCursor = value.slice(0, cursorPosition).replace(/@/g, ' ');
        const lastAtSymbolIndex = textBeforeCursor.lastIndexOf(' ');
        if (lastAtSymbolIndex !== -1) {
            const query = textBeforeCursor.slice(lastAtSymbolIndex);
            const filtered = selectedFallBackParametersText.filter((suggestion) =>
                suggestion.startsWith(query.trim())
            );
            setFallbackFilteredSuggestions(filtered);
            setFallbackMessageIndexDropdown(true);
        } else {
            setFallbackMessageIndexDropdown(false);
        }
        setFallbackCursorSelection(cursorPosition);
    };
    //#endregion

    //#region Config section

    //#region Message Build
    const columnsMessageBuild = [
        { title: 'ID', dataIndex: 'messageRowId', key: 'messageRowId', hidden: true, },
        { title: 'Title', dataIndex: 'messageTitle', key: 'messageTitle', width: 100, },
        { title: 'Parameter', dataIndex: 'messageParameter', key: 'messageParameter', width: 100, render: (text) => renderTags(text), },
        { title: 'Message', dataIndex: 'messageTextArea', key: 'messageTextArea', width: 100 },
        {
            title: 'Actions', width: 60, key: 'actions', render: (text, record) =>
            (<> <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => editMessageBuildItem(record)}> <EditOutlined /> </Button>
                <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} danger onClick={() => deleteMessageBuildItem(record.messageRowId)}> <DeleteOutlined /> </Button> </>),
        },
    ];

    const renderTags = (tags) => {
        if (tags !== undefined && tags !== null && tags !== '') {
            return (
                <span>
                    {tags.map((tag, index) => (
                        <Tag color="blue" key={index}>
                            {tag}
                        </Tag>
                    ))}
                </span>
            );
        }
    };

    const tagParameterMultiRender = ({ label, value, closable, onClose }) => {
        const maxDisplay = CONST_MAPPING.MULTISELECT_MAX_DISPLAY;
        const index = selectedParametersText.indexOf(value);

        if (index < maxDisplay) {
            return (
                <div className="ant-select-selection-item">
                    {label}
                    {closable && (
                        <span
                            onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onClick={onClose}
                            className="ant-select-selection-item-remove"
                        >
                            ×
                        </span>
                    )}
                </div>
            );
        }

        if (index === maxDisplay) {
            return (
                <div className="ant-select-selection-item">
                    +{selectedParametersText.length - maxDisplay} more
                </div>
            );
        }

        return null;
    };

    const addMessageBuildItem = async () => {
        try {
            setTextAreaValue(messageTextArea);
            const values = await formMessage.validateFields(['messageTitle', 'messageParameter', 'messageTextArea']);
            if (isMsgBuildEditing) {
                const updatedData = messageBuildData.map((item) =>
                    item.messageRowId === msgBuildEditingItem.messageRowId ? { ...item, ...values } : item
                );
                setMessageBuildData(updatedData);
                setMsgBuildEditingItem(null);
                setMsgBuildIsEditing(false);
            } else {
                const msgText = messageTextArea;
                const newData = {
                    messageRowId: messageBuildData.length ? messageBuildData[messageBuildData.length - 1].messageRowId + 1 : 1,
                    messageTextArea: msgText,
                    ...values,
                };
                setMessageBuildData([...messageBuildData, newData]);
            }
            setSelectedParametersText([]);
            //setSelectedParameters([]);
            setTextAreaValue('');
            formMessage.setFieldsValue({ messageParameter: [], messageTitle: '', messageTextArea: '' });
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    const editMessageBuildItem = (record) => {
        console.log(record);
        formMessage.setFieldsValue(record);
        setMsgBuildEditingItem(record);
        setTextAreaValue(record.messageTextArea || '');
        //setFilteredSuggestions(record.messageParameter || []);
        const updatedSuggestList = record.messageParameter.map(item => ({
            label: item, value: `${item}]`
        }));
        setFilteredSuggestions(updatedSuggestList || []);
        setSelectedParametersText(record.messageParameter || []);
        setmessageTitle(record.messageTitle || '');
        setMsgBuildIsEditing(true);
        setMessageIndexDropdown(false);
    }

    const addMessageBuildClear = () => {
        formMessage.resetFields();
        formMessage.setFieldsValue({ messageParameter: [], messageTitle: '', messageTextArea: '' });
        setMsgBuildEditingItem(null);
        setTextAreaValue('');
        setFilteredSuggestions([]);
        setSelectedParametersText([]);
        setMsgBuildIsEditing(false);
        setMessageIndexDropdown(false);
        setmessageTitle('');
    }

    const closeSuggestions = () => {
        setMessageIndexDropdown(false);
    }

    const deleteMessageBuildItem = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                setMessageBuildData(messageBuildData.filter((item) => item.messageRowId !== id));
            },
            onCancel() {
            },
        });
    }

    const clickParamenterChange = (value, option) => {
        const updatedSuggestList = option.map(item => ({
            label: item.value,
            value: `${item.value}]`
        }));
        setFilteredSuggestions(updatedSuggestList);
        setSelectedParametersText(value);
        //setSelectedParametersText(option.map(opt => opt.children));        
    }
    //#endregion

    const handleConfigAdd = async () => {
        try {
            await form.validateFields(['processType']).then(values => {
                if (processType) {
                    const newEntry = {
                        processId: entries.length ? entries[entries.length - 1].processId + 1 : 1,
                        processType,
                        data: {}
                    };
                    setEntries([...entries, newEntry]);
                    form.setFieldsValue({ processType: "0" });
                    setProcessType("0");
                }
            });
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    const handleConfigDelete = (index) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                const newEntries = entries.filter((_, i) => i !== index);
                setEntries(newEntries);
            },
        });
    };

    const handleConfigEdit = (index) => {
        setCurrentEntryIndex(index);
        const entry = entries[index];
        const entryData = entry.data || {};

        if (entry.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_SP) {
            setName(entryData.spId || spId);
            setDrawerTitle("SP CALL");
        } else if (entry.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_MESSAGE) {
            //setTextAreaValue(entryData.message || '');
            setMessageBuildData(entryData.messageBuildData || messageBuildData);
            setDrawerTitle("MESSAGE BUILD");
        } else if (entry.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_API) {
            setDrawerTitle("API CONFIG");
            setFunctionType(entryData.functionType || functionType);
            setFunctionName(entryData.functionNameId || functionNameId);
        }

        setDrawerOpen(true);
    };

    const handleConfigSave = () => {
        const newEntries = [...entries];
        if (entries[currentEntryIndex].processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_SP) {
            newEntries[currentEntryIndex] = {
                ...newEntries[currentEntryIndex],
                data: {
                    spId
                }
            };
        } else if (entries[currentEntryIndex].processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_MESSAGE) {
            newEntries[currentEntryIndex] = {
                ...newEntries[currentEntryIndex],
                data: {
                    //message
                    messageBuildData
                }
            };
        } else if (entries[currentEntryIndex].processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_API) {
            newEntries[currentEntryIndex] = {
                ...newEntries[currentEntryIndex],
                data: {
                    functionType,
                    functionNameId
                }
            };
        }
        setEntries(newEntries);
        setDrawerOpen(false);
    };
    //#endregion

    //#region Fallback Section
    const columnsFallback = [
        { title: 'ID', dataIndex: 'fallbackId', key: 'fallbackId', hidden: true, },
        { title: 'Type', dataIndex: 'fallbackType', key: 'fallbackType', width: 100, hidden: true },
        { title: 'Type', dataIndex: 'fallbackTypeText', key: 'fallbackTypeText', width: 100 },
        { title: 'Parameter', dataIndex: 'fallbackParameter', key: 'fallbackParameter', width: 100, render: (text) => renderTags(text), },
        { title: 'Message', dataIndex: 'fallbackMessage', key: 'fallbackMessage', width: 100 },
        {
            title: 'Actions', width: 60, key: 'actions', render: (text, record) =>
            (<> <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => editFallbackItem(record)}> <EditOutlined /> </Button>
                <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} danger onClick={() => deleteFallbackItem(record.fallbackId)}> <DeleteOutlined /> </Button> </>),
        },
    ];

    const handleFallbackTypeChange = (value, option) => {
        setfallbackTypeText(option.children);
    };
    const handleCancel = () => {
        form.resetFields();
        setTextAreaValue('');
    };
    const clickFallbackParamenterChange = (value, option) => {
        const updatedSuggestList = option.map(item => ({
            label: item.value,
            value: `${item.value}]`
        }));
        setFallbackFilteredSuggestions(updatedSuggestList);
        setselectedFallBackParametersText(value);
    }
    const tagFallbackParameterMultiRender = ({ label, value, closable, onClose }) => {
        const maxDisplay = CONST_MAPPING.MULTISELECT_MAX_DISPLAY;
        const index = selectedFallBackParametersText.indexOf(value);

        if (index < maxDisplay) {
            return (
                <div className="ant-select-selection-item">
                    {label}
                    {closable && (
                        <span
                            onMouseDown={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                            }}
                            onClick={onClose}
                            className="ant-select-selection-item-remove"
                        >
                            ×
                        </span>
                    )}
                </div>
            );
        }

        if (index === maxDisplay) {
            return (
                <div className="ant-select-selection-item">
                    +{selectedFallBackParametersText.length - maxDisplay} more
                </div>
            );
        }

        return null;
    };

    const editFallbackItem = (record) => {
        form.setFieldsValue(record);
        setEditingItem(record);
        setfallbackTypeText(record.fallbackTypeText || '');
        setFallbackTextMessage(record.fallbackMessage || '');
        setselectedFallBackParametersText(record.fallbackParameter || []);
        const updatedSuggestList = record.fallbackParameter.map(item => ({
            label: item, value: `${item}]`
        }));
        setFallbackFilteredSuggestions(updatedSuggestList || []);
        setIsEditing(true);
        setFallbackMessageIndexDropdown(false);
    };
    const addFallbackClear = async () => {
        form.resetFields();
        form.setFieldsValue({ fallbackParameter: [], fallbackMessage: '' });
        setIsEditing(false);
        setEditingItem(null);
        setfallbackTypeText('');
        setFallbackTextMessage('');
        setselectedFallBackParametersText([]);
        setFallbackFilteredSuggestions([]);
        setFallbackMessageIndexDropdown(false);
    }

    const closeFallbackSuggestions = () => {
        setFallbackMessageIndexDropdown(false);
    }

    const addFallbackItem = async () => {
        try {
            setFallbackTextMessage(fallbackTextMessage);
            const values = await form.validateFields(['fallbackType', 'fallbackParameter', 'fallbackMessage']);
            if (isEditing) {
                if (fallbackData.some(item => item.fallbackType === values.fallbackType && item.fallbackType !== editingItem.fallbackType)) {
                    return;
                }
                const selectedText = fallbackTypeText;
                const updatedData = fallbackData.map((item) =>
                    item.fallbackId === editingItem.fallbackId ? { ...item, fallbackTypeText: selectedText, ...values } : item
                );

                setFallbackData(updatedData);
                setEditingItem(null);
                setIsEditing(false);
            } else {
                if (fallbackData.some(item => item.fallbackType === values.fallbackType)) {
                    return;
                }
                const selectedText = fallbackTypeText;
                const typedMsgText = fallbackTextMessage;
                const newData = {
                    fallbackId: fallbackData.length ? fallbackData[fallbackData.length - 1].fallbackId + 1 : 1,
                    fallbackTypeText: selectedText, // Store selected fallbackTypeText in table column
                    fallbackMessage: typedMsgText,
                    ...values,
                };
                setFallbackData([...fallbackData, newData]);
            }
            form.resetFields();
            setFallbackTextMessage('');
            form.setFieldsValue({ fallbackParameter: [], fallbackMessage: '' });
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    const deleteFallbackItem = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                setFallbackData(fallbackData.filter((item) => item.fallbackId !== id));
            },
            onCancel() {
            },
        });
    };
    //#endregion

    //#region PossibleResponse
    const clickPossibleDrawer = () => {
        setToggleResponseDrawer(true);
    }

    const clickFallbackDrawer = () => {
        setToggleFallbackDrawer(true);
    }

    const handlePossibleResponseChange = (value, option) => {
        setResponseGroupText(option.children);
    };

    const editResponseGroupItem = (record) => {
        form.setFieldsValue(record);
        setResponseEditingItem(record);
        setResponseGroupText(record.keywordText);
        setIsResponseEditing(true);
    };

    const addResponseItem = async () => {
        try {
            const values = await form.validateFields(['keywordTextId', 'keywordPriority']);
            if (isResponseEditing) {
                if (possibleResponseData.some(item => item.keywordTextId === values.keywordTextId && item.keywordTextId !== editResponseItem.keywordTextId)) {
                    return;
                }
                else if (possibleResponseData.some(item => item.keywordPriority === values.keywordPriority && item.keywordPriority !== editResponseItem.keywordPriority)) {
                    return;
                }
                const selectedText = resGroupText;
                const updatedData = possibleResponseData.map((item) =>
                    item.responseGroupId === editResponseItem.responseGroupId ? { ...item, keywordText: selectedText, ...values } : item
                );

                setpossibleResponseData(updatedData);
                setResponseEditingItem(null);
                setIsResponseEditing(false);
            } else {
                if (possibleResponseData.some(item => item.keywordTextId === values.keywordTextId)) {
                    return;
                }
                else if (possibleResponseData.some(item => item.keywordPriority === values.keywordPriority)) {
                    return;
                }
                const selectedText = resGroupText;
                const newData = {
                    responseGroupId: possibleResponseData.length ? possibleResponseData[possibleResponseData.length - 1].responseGroupId + 1 : 1,
                    keywordText: selectedText, // Store selected keywordText in table column
                    ...values,
                };
                setpossibleResponseData([...possibleResponseData, newData]);
            }
            form.resetFields();
        } catch (errorInfo) {
            console.log('Validate Failed:', errorInfo);
        }
    };

    const deleteResponseGroupItem = (id) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            onOk() {
                setpossibleResponseData(possibleResponseData.filter((item) => item.responseGroupId !== id));
            },
            onCancel() {
            },
        });
    };

    //#endregion

    //#region Component End stage
    const onComponentEndCallChange = (e) => {
        setComponentEndCall(e.target.checked);
    }
    const onComponentEndOperatorChange = (e) => {
        setComponentEndOperator(e.target.checked);
    }
    const onPauseRecordChange = (e) => {
        setpauseRecord(e.target.checked);
    }
    //#endregion

    //#region Call config
    const onChangeCallLatency = (value) => {
        setspeechTimeout(value);
    }

    const onChangeIvrCharLimit = (value) => {
        setIvrCharLimit(value);
    }
    const ChangeIncomingResponseType = (value) => {
        setIncomingResponseType(value);
    }
    const ChangeMessageType = (value) => {
        setMessageType(value);
    }

    //#endregion

    //#region FinalSave
    const handleSaveComponent = () => {

        const config = entries; //Get Config Data
        const fallbackMessage = fallbackData;  //Get Fallback Messages Data       
        const possibleResponse = possibleResponseData; //Get Possible Response //Old - selectedPossibleResponse; 

        const endCall = componentEndCall ? 1 : 0; //Get Component End Call;  Converts the boolean to 1 or 0
        const connectOperator = componentEndOperator ? 1 : 0; //Get Component End Call Operator; Converts the boolean to 1 or 0
        const pauseRecording = pauseRecord ? 1 : 0; //Get Component pauseRecord; Converts the boolean to 1 or 0    
        const communicationType = messageType; //messageType 'Text' or Voice

        const speechTimeout = speechTimeoutNum; //Get Call Listen Latency
        const ivrCharacterLimit = ivrCharLimit; //Get Call ivr Char Limit
        const incomingTextType = incomingResponseType; //Get Incoming Response TYPE

        // Combine all data
        const compConfig = { config, fallbackMessage, possibleResponse, endCall, connectOperator, pauseRecording, communicationType, speechTimeout, ivrCharacterLimit, incomingTextType };

        //Get Line section Data             
        const updateLines = tableData.map((item) => {
            const lineLblText = item.lineText.split('|')[0];
            if (item.lineType.toUpperCase() === CONST_MAPPING.NEXT_WORKFLOW) {
                return { ...item, lineText: `${lineLblText}|${item.lineNextFlow}` };
            } else if (item.lineType.toUpperCase() === CONST_MAPPING.SAME_COMPONENT) {
                return { ...item, lineText: `${lineLblText}|${CONST_MAPPING.SAME_COMPONENT}` };
            }
            return item;
        });

        //Default line Items
        const defaultLines = { key: -1, lineType: "nextworkflow", lineNextFlow: CONST_MAPPING.OPERATOR_WORKFLOW_ID, lineText: CONST_MAPPING.OPERATOR_LINE_TEXT + "|" + CONST_MAPPING.OPERATOR_WORKFLOW_ID };

        let linesData = { lines: updateLines };
        linesData.lines = [...linesData.lines, defaultLines];

        const fullData = { key: nodeKey, configData: compConfig, configLines: linesData };
        console.log("branch:", fullData);
        passOutputToParent(JSON.stringify(fullData));

    };
    //#endregion

    return (
        <div className='component-container'>
            <div className='cus-btn-fixed-top'>
                {/* <Button type="primary" style={{ width: "inherit" }} onClick={handleSaveComponent}>
                    Save
                </Button> */}
            </div>
            <div style={{ marginTop: "10px" }}>
                <div>{!isUserRestricted && (
                    <>
                        <Card className='cus-card'
                            title={
                                <Row align="middle">
                                    <Col>
                                        <SettingOutlined className='cus-card-title-icon' />
                                    </Col>
                                    <Col>
                                        <span>Settings</span>
                                    </Col>
                                </Row>
                            }
                        >

                            <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                <label>Process Type</label>
                            </div>
                            <Form
                                form={form}
                                layout="inline"
                                name="form-config">
                                <Form.Item
                                    name="processType"
                                    // label="Process Type"
                                    rules={[
                                        { required: true, message: 'Field should not be empty' }, {
                                            validator: (_, value) =>
                                                value !== '0' ? Promise.resolve() : Promise.reject('Field should not be empty')
                                        }]}
                                    initialValue={"0"}
                                >
                                    <Select
                                        value={processType}
                                        //defaultValue="0"
                                        onChange={setProcessType}
                                        style={{ width: 200, height: 28 }}
                                    >
                                        {/* If Changes made at 'value' propertity of below also change 'CONST_MAPPING' variable values */}
                                        <Option key="0" value="0">Select</Option>
                                        {masterConfig && <Option key="API Config" value="API Config">API Config</Option>}
                                        <Option key="Message Build" value="Message Build">Message Build</Option>
                                        {masterConfig && <Option key="SP Call" value="SP Call">SP Call</Option>}

                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" onClick={handleConfigAdd}>Add</Button>
                                </Form.Item>

                            </Form>

                            <Table scroll={{ x: 'max-content', y: 150 }} pagination={false} className='cus-table' dataSource={entries.map((entry, index) => ({ ...entry, key: index }))} style={{ marginTop: '20px' }}>
                                <Table.Column title="Process ID" dataIndex="processId" key="processId" hidden />
                                <Table.Column title="Process Type" dataIndex="processType" key="processType" />
                                <Table.Column title="Data" dataIndex="data" key="data" hidden
                                    render={(data) => JSON.stringify(data)}
                                />
                                <Table.Column title="Actions" key="actions" width={20}
                                    render={(_, __, index) => (
                                        <Space>
                                            <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} icon={<EditOutlined />} onClick={() => handleConfigEdit(index)} />
                                            <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} icon={<DeleteOutlined />} onClick={() => handleConfigDelete(index)} />
                                        </Space>
                                    )}
                                />
                            </Table>
                        </Card>
                        <Drawer
                            title={drawerTitle}
                            className='compDrawer'
                            onClose={() => setDrawerOpen(false)}
                            open={drawerOpen}
                            footer={
                                <div style={{ textAlign: 'right' }}>
                                    <Button onClick={() => setDrawerOpen(false)} style={{ marginRight: 8 }}>Cancel</Button>
                                    <Button onClick={handleConfigSave} type="primary">Save</Button>
                                </div>
                            }
                        >
                            {entries[currentEntryIndex]?.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_SP ? (
                                <Form form={form} layout="vertical">
                                    <Form.Item label="Stored Procedure Name" initialValue={0}>
                                        <Select
                                            value={spId}
                                            //defaultValue={0} 
                                            onChange={setName} style={{ width: '100%', height: 28 }}>
                                            <Option value="0">Select</Option>
                                            {listSp.map((option) => (
                                                <Option key={option.storeProcedureListId} value={option.storeProcedureListId}>{option.list}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            ) : entries[currentEntryIndex]?.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_MESSAGE ? (
                                <Form form={formMessage} name='form-messagebuild' layout="vertical">
                                    <Form.Item
                                        name="messageTitle"
                                        label="Title"
                                        initialValue={messageTitle}
                                        rules={[{ required: true, message: 'Field should not be empty' }]}
                                    >
                                        <Input maxLength={50} onChange={(e) => setmessageTitle(e.target.value)}></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="messageParameter"
                                        label={
                                            <span>
                                                <span style={{ marginRight: "10px" }}>Select parameters</span>
                                                <Tooltip title="Selected parameters are only allowed to use in Message">
                                                    <ExclamationCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        }
                                        initialValue={selectedParametersText}
                                    // rules={[
                                    //     { required: true, message: 'Field should not be empty' }, {
                                    //         validator: (_, value) =>
                                    //             value !== '' ? Promise.resolve() : Promise.reject('Field should not be empty')
                                    //     }]}
                                    >
                                        <Select
                                            mode='multiple'
                                            placeholder="Select"
                                            tagRender={tagParameterMultiRender}
                                            onChange={clickParamenterChange} value={selectedParametersText}>
                                            {messageIndexes.map((option) => (
                                                <Option key={option.parameterId} value={option.messageIndexData.replace('@', '')}>{option.messageIndexData.replace('@', '')}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="messageTextArea"
                                        label="Message"
                                        initialValue={messageTextArea}
                                        rules={[{ required: true, message: 'Field should not be empty' }]}
                                    >
                                        <Mentions
                                            rows={5}
                                            style={{ width: '100%', minHeight: '90px' }}
                                            prefix={['[']}
                                            options={filteredSuggestions}
                                        />
                                    </Form.Item>
                                    {/* <Form.Item
                                        name="messageTextArea"
                                        label="Message"
                                        initialValue={messageTextArea}
                                        rules={[{ required: true, message: 'Field should not be empty' }]}
                                    >
                                        <div>
                                            <TextArea
                                                ref={textareaRef}
                                                value={messageTextArea}
                                                onChange={handleMessageInputChange}
                                                style={{ minHeight: '90px' }} />
                                        </div>
                                    </Form.Item>
                                    {showMessageIndex && filteredSuggestions.length > 0 && (
                                        <Form.Item>
                                            <a className='ul-suggestion-close' onClick={closeSuggestions}><CloseCircleOutlined /></a>
                                            <ul className='ul-messageIndexes' >
                                                {filteredSuggestions.map((suggestion) => (
                                                    <li
                                                        key={suggestion}
                                                        onClick={() => handleSuggestionClick(suggestion)}
                                                    >
                                                        {suggestion}
                                                    </li>
                                                ))}
                                            </ul>
                                        </Form.Item>
                                    )} */}
                                    <Form.Item style={{ textAlign: 'right' }}>
                                        {
                                            isMsgBuildEditing && (
                                                <Button style={{ marginRight: '10px' }} className='ant-btn-sm' type="primary" onClick={addMessageBuildClear}>Clear</Button>)
                                        }
                                        <Button className='ant-btn-sm' type="primary" onClick={addMessageBuildItem}>
                                            {isMsgBuildEditing ? 'Update' : 'Add'}
                                        </Button>
                                    </Form.Item>
                                    <Table scroll={{ x: 'max-content', y: 150 }} pagination={false} className='cus-table' columns={columnsMessageBuild} dataSource={messageBuildData} />
                                </Form>
                            ) : entries[currentEntryIndex]?.processType.toUpperCase() === CONST_MAPPING.PROCESS_TYPE_API ? (
                                <Form form={form} layout="vertical">
                                    <Form.Item label="Function Type" initialValue={0}>
                                        <Select
                                            value={functionType}
                                            //defaultValue={0} 
                                            onChange={setFunctionType} style={{ width: '100%', height: 28 }}>
                                            <Option value="0">Select</Option>
                                            {functionTypes.map((option) => (
                                                <Option key={option.apiFunctionTypeId} value={option.apiFunctionType}>{option.apiFunctionType}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Function Name" initialValue={0}>
                                        {/* <Input value={functionNameId} onChange={(e) => setFunctionName(e.target.value)} /> */}
                                        <Select
                                            //defaultValue={0} 
                                            onChange={setFunctionName} value={functionNameId} style={{ height: 28 }}>
                                            <Option value="0">Select</Option>
                                            {functionNames.map((option) => (
                                                <Option key={option.functionCallListId} value={option.functionCallListId}>{option.apiFunctionName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            ) : (
                                <Typography.Text>Select a valid process type to edit.</Typography.Text>
                            )}
                        </Drawer></>
                )}
                </div>
                {/* <Divider dashed /> */}
                <div>
                    <Drawer
                        title="Possible Response"
                        className='compDrawer'
                        onClose={() => setToggleResponseDrawer(false)}
                        open={toggleResponseDrawer}
                    >
                        {/* <Card className='cus-card'
                            title={
                                <Row align="middle">
                                    <Col>
                                        <GroupOutlined className='cus-card-title-icon' />
                                    </Col>
                                    <Col>
                                        <span>Possible Response Keyword</span>
                                    </Col>
                                </Row>
                            }
                        > */}
                        <Form form={form} name='form-possible-response' layout="vertical">
                            <Form.Item
                                name="keywordTextId"
                                label="Possible Response Keyword"
                                rules={[{ required: true, message: 'Field should not be empty' }]}
                            >
                                <Select
                                    placeholder="select"
                                    onChange={handlePossibleResponseChange} value={selectedPossibleResponse} style={{ width: '100%', height: 28 }}
                                >
                                    {PossibleResponseGroups.map((option) => (
                                        <Option key={option.groupId} value={option.groupId} name={option.groupName}>{option.groupName}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="keywordPriority"
                                label="Priority"
                                initialValue={0}
                            >
                                <Select>
                                    {generateIntegerOptions(priorityNumber).map((num) => (
                                        <option key={num} value={num}>{num}</option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item style={{ textAlign: 'right' }}>
                                <Button className='ant-btn-sm' type="primary" onClick={addResponseItem}>
                                    {isResponseEditing ? 'Update' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Table scroll={{ x: 'max-content', y: 150 }} pagination={false} className='cus-table' columns={columnsResponseGroup} dataSource={possibleResponseData} rowKey="responseGroupId" />
                    </Drawer>
                    {/* </Card> */}
                </div>
                {/* <Divider dashed /> */}
                <div>
                    <Drawer title="Fallback Message"
                        className='compDrawer'
                        onClose={() => setToggleFallbackDrawer(false)}
                        open={toggleFallbackDrawer}
                    >
                        <Form form={form} name='form-fallback' layout="vertical">
                            <Form.Item
                                name="fallbackType"
                                label="Fallback Type"
                                rules={[{ required: true, message: 'Field should not be empty' }]}
                            >
                                <Select placeholder="Select" style={{ height: 28 }} onChange={handleFallbackTypeChange}>
                                    <Option key="1" value="1">Fallback 1</Option>
                                    <Option key="2" value="2">Fallback 2</Option>
                                    <Option key="3" value="3">Fallback 3</Option>
                                    <Option key="4" value="4">Fallback 4</Option>
                                    <Option key="5" value="5">Fallback 5</Option>
                                    <Option key="6" value="6">Fallback 6</Option>
                                    <Option key="7" value="7">Fallback 7</Option>
                                    <Option key="8" value="8">Fallback 8</Option>
                                    <Option key="9" value="9">Fallback 9</Option>
                                    <Option key="10" value="10">Fallback 10</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="fallbackParameter"
                                label={
                                    <span>
                                        <span style={{ marginRight: "10px" }}>Select parameters</span>
                                        <Tooltip title="Selected parameters are only allowed to use in Message">
                                            <ExclamationCircleOutlined />
                                        </Tooltip>
                                    </span>
                                }
                                initialValue={selectedFallBackParametersText}>
                                <Select
                                    mode='multiple'
                                    placeholder="Select"
                                    tagRender={tagFallbackParameterMultiRender}
                                    onChange={clickFallbackParamenterChange} value={selectedFallBackParametersText}>
                                    {messageIndexes.map((option) => (
                                        <Option key={option.parameterId} value={option.messageIndexData.replace('@', '')}>{option.messageIndexData.replace('@', '')}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="fallbackMessage"
                                label="Fallback Message"
                                initialValue={fallbackTextMessage}
                            >
                                <Mentions
                                    rows={5}
                                    style={{ width: '100%', minHeight: '90px' }}
                                    prefix={['[']}
                                    options={fallbackfilteredSuggestions}
                                />
                            </Form.Item>
                            {/* <Form.Item
                                    name="fallbackMessage"
                                    label="Fallback Message"
                                    initialValue={fallbackTextMessage}
                                >
                                    <div>
                                        <TextArea
                                            ref={fallbackTextareaRef}
                                            value={fallbackTextMessage}
                                            onChange={handleFallbackMessageInputChange}
                                            style={{ minHeight: '90px' }} />
                                    </div>
                                </Form.Item>
                                {showFallbackMessageIndex && fallbackfilteredSuggestions.length > 0 && (
                                    <Form.Item>
                                        <a className='ul-suggestion-close' onClick={closeFallbackSuggestions}><CloseCircleOutlined /></a>
                                        <ul className='ul-messageIndexes' >
                                            {fallbackfilteredSuggestions.map((suggestion) => (
                                                <li
                                                    key={suggestion}
                                                    onClick={() => handleFallbackSuggestionClick(suggestion)}
                                                >
                                                    {suggestion}
                                                </li>
                                            ))}
                                        </ul>
                                    </Form.Item>
                                )} */}
                            <Form.Item style={{ textAlign: 'right' }}>
                                {
                                    isEditing && (
                                        <Button style={{ marginRight: '10px' }} className='ant-btn-sm' type="primary" onClick={addFallbackClear}>Clear</Button>)
                                }
                                <Button className='ant-btn-sm' type="primary" onClick={addFallbackItem}>
                                    {isEditing ? 'Update' : 'Add'}
                                </Button>
                            </Form.Item>
                        </Form>
                        <Table scroll={{ x: 'max-content', y: 150 }} pagination={false} className='cus-table' columns={columnsFallback} dataSource={fallbackData} rowKey="fallbackId" />
                    </Drawer>
                </div>

                <div>{!isUserRestricted && (
                    <>
                        <Divider dashed />
                        <Card className='cus-card'
                            title={
                                <Row align="middle">
                                    <Col>
                                        <LinkOutlined className='cus-card-title-icon' />
                                    </Col>
                                    <Col>
                                        <span>Line Section</span>
                                    </Col>
                                </Row>
                            }
                        >
                            <Form form={form} name='form-lines' layout="vertical">
                                <Form.Item label="Line Type" name="lineType"
                                    rules={[{ required: true, message: 'Field should not be empty' }]}>
                                    <Select onChange={handleLineTypeChange} style={{ height: 28 }} placeholder="Select">
                                        <Option key="nextcomponent" value="nextcomponent">Next Component</Option>
                                        <Option key="nextworkflow" value="nextworkflow">Next Workflow</Option>
                                        <Option key="samecomponent" value="samecomponent">Same Component</Option>
                                    </Select>
                                </Form.Item>
                                {showWorkflowDropdown && (
                                    <Form.Item label="Select Next Workflow" name="lineNextFlow" initialValue={0}
                                    // rules={[
                                    //     {
                                    //         required: true,
                                    //         message: 'Field should not be empty'
                                    //     },
                                    //     {
                                    //         validator: (_, value) =>
                                    //             value !== 0 ? Promise.resolve() : Promise.reject('Field should not be empty')
                                    //     }
                                    // ]}
                                    >
                                        <Select
                                            onChange={handleWorkflowChange}
                                            //defaultValue={0} 
                                            style={{ height: 28 }}>
                                            <Option value={0}> Select</Option>
                                            {workflows.map((option) => (
                                                <Option key={option.workFlowId} value={option.workFlowId}>{option.workFlowName}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                                <Form.Item label="Line Text" name="lineText"
                                    rules={[{ required: true, message: 'Field should not be empty' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'right' }}>
                                    {editingKey === '' ? (
                                        <Button className='ant-btn-sm' type="primary" onClick={handleLineAdd}>
                                            Add
                                        </Button>
                                    ) : (
                                        <Button className='ant-btn-sm' type="primary" onClick={handleLineUpdate}>
                                            Update
                                        </Button>
                                    )}
                                </Form.Item>
                            </Form>
                            <Table scroll={{ x: 150, y: 150 }} className='cus-table' dataSource={tableData} columns={columnsLine} pagination={false} />
                        </Card></>
                )}
                </div>
                <Divider dashed />
                <div>
                    <Card className='cus-card'
                        title={
                            <Row align="middle">
                                <Col>
                                    <FlagOutlined className='cus-card-title-icon' />
                                </Col>
                                <Col>
                                    <span>Component Flag indicator</span>
                                    <span style={{ paddingLeft: "10px" }}>
                                        <Tooltip title="If this is the final state of the component, only check it; if not, leave it unchecked.">
                                            <ExclamationCircleOutlined />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        }
                    >
                        <Row gutter={[20, 20]}>
                            <Col>
                                <Checkbox checked={componentEndCall} onChange={onComponentEndCallChange}>End Conversation</Checkbox>
                            </Col>
                            <Col>
                                <Checkbox checked={componentEndOperator} onChange={onComponentEndOperatorChange}>Connect Operator</Checkbox>
                            </Col>
                            <Col>
                                <Checkbox checked={pauseRecord} onChange={onPauseRecordChange}>Pause Record</Checkbox>
                            </Col>
                            <Col span={[10]}>
                                <Row span={[24]}>
                                    <Col span={[10]}>
                                        <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                            <label>Message Type</label>
                                            <span style={{ paddingLeft: "10px" }}>
                                                <Tooltip title="Change message type either 'Text' or 'Voice'. Default type is 'Voice'">
                                                    <ExclamationCircleOutlined />
                                                </Tooltip>
                                            </span>
                                        </div>
                                    </Col>

                                    <Col span={[12]} style={{ marginBottom: "10px" }}>
                                        <Select style={{ width: "100%" }} value={messageType} placeholder="Select" onChange={ChangeMessageType}>
                                            <Option text="Voice" value="Voice"></Option>
                                            <Option text="Text" value="Text"></Option>
                                            <Option text="Text" value="DDMF"></Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>
                <Divider dashed />
                <div>
                    <Card className='cus-card'
                        title={
                            <Row align="middle">
                                <Col>
                                    <PhoneOutlined className='cus-card-title-icon' />
                                </Col>
                                <Col>
                                    <span>Call Configuration</span>
                                    <span style={{ paddingLeft: "10px" }}>
                                        <Tooltip title="Set values if the step needs this configuration otherwise leave it as ZERO(0) in number">
                                            <ExclamationCircleOutlined />
                                        </Tooltip>
                                    </span>
                                </Col>
                            </Row>
                        }
                    >
                        <Row gutter={[20, 20]}>
                            <Col>
                                <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                    <label>Speech Timeout</label>
                                </div>
                                <InputNumber value={speechTimeoutNum} min={0} max={10000} onChange={onChangeCallLatency} />
                            </Col>
                            <Col>
                                <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                    <label>IVR Character Limit</label>
                                </div>
                                <InputNumber value={ivrCharLimit} min={0} max={35} onChange={onChangeIvrCharLimit} />
                            </Col>
                            <Col span={[6]}>
                                <div className='cus_lable' style={{ marginBottom: "10px" }}>
                                    <label>Response Type</label>
                                    <span style={{ paddingLeft: "10px" }}>
                                        <Tooltip title="Select if you expect an incoming response 'TYPE' otherwise leave it empty">
                                            <ExclamationCircleOutlined />
                                        </Tooltip>
                                    </span>
                                </div>
                                <Select style={{ width: "100%" }} mode='multiple' value={incomingResponseType} placeholder="Select" onChange={ChangeIncomingResponseType}>
                                    {/* <Option text="Text" value="Text"></Option>
                                    <Option text="Number" value="Number"></Option>
                                    <Option text="Date" value="Date"></Option>
                                    <Option text="OptionsText" value="OptionsText"></Option>
                                    <Option text="Boolean" value="Boolean"></Option> */}
                                    {incomingResponseTypeData.map((option) => (
                                        <Option key={option.responseId} value={option.responseId}>{option.responseName}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Card>
                </div>

            </div>
            <Divider dashed />
            <div>
                <Flex gap="small" wrap>
                    {!isUserRestricted && (
                        <>  <Button icon={<GroupOutlined />} type="primary" onClick={clickPossibleDrawer}>Possible Response</Button></>
                    )}
                    <Button icon={<RollbackOutlined />} type="primary" onClick={clickFallbackDrawer}>Fallback</Button>
                </Flex>
            </div>
            <Form.Item>
                <Row justify="end" gutter={8}>
                    <Col>
                        <Button type="primary" htmlType="submit" onClick={handleSaveComponent}>
                            Save
                        </Button>
                    </Col>
                    <Col>
                        <Button type="default" onClick={handleCancel}>
                           Clear
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </div>
    );
};

export default BranchComponent;