import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Form, Input, Tabs, Modal, Row, Col, Table, Card, Tooltip, Spin } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, UserAddOutlined, PhoneOutlined, StopOutlined, PhoneFilled, StopFilled, EyeFilled } from '@ant-design/icons';
import { fetchMetaData, saveMetaData, initCall, disconnectCall,getConvRecordingsAndMessages } from '../../../services/workFlowService.js';
import * as XLSX from 'xlsx';
import ConversationModal from './conversationModal.jsx';

const WorkflowMetadata = ({ workflowId, onSendData }) => {
    const [patDataSource, setPatDataSource] = useState([]);
    const [loading, setLoading] = useState(false);
    const [patFileName, setPatFileName] = useState('');
    const baseURL = process.env.REACT_APP_BASE_API_URI;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentWorkflowId, setCurrentWorkflowId] = useState(workflowId);
    const [metaDataSource, setMetaDataSource] = useState([]);
    const [metaDataColumns, setMetaDataColumns] = useState([]);
    const [isConversationModalVisible, setIsConversationModalVisible] = useState(false);
    const [conversationData, setConversationData] = useState({ recordings: [], messages: [] });

    useEffect(() => {
        //Load MetaData by Workflowid
        setLoading(true);
        async function fetchData(currentWorkflowId) {
            try {
                await fetchMetaDataByWorkflow(currentWorkflowId);
            } catch (error) {
                console.error('Error fetching location data:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchData(currentWorkflowId);
    }, [currentWorkflowId]);

    const constantMetaDataColumns = [
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <>
                <Tooltip title="Initiate Call">
                    <Button
                        type="link"
                        onClick={() => initiateCall(record)}
                    >
                        <PhoneFilled />
                    </Button>
                </Tooltip>
                <Tooltip title="View Conversation">
                    <Button
                        type="link"
                        onClick={() => viewConversation(record)}
                    >
                        <EyeFilled />
                    </Button>
                </Tooltip>
                {/* <Tooltip title="Stop Call">
                    <Button
                        type=""
                        icon={<StopFilled />}
                        onClick={() => stopCall(record)}
                    />
                </Tooltip> */}
            </>
            ),
            width: 120,
            align: 'center',
        },
        // {
        //     title: 'Stop',
        //     dataIndex: 'stop',
        //     key: 'stop',
        //     render: (text, record) => (
        //         <Tooltip title="Stop Call">
        //             <Button
        //                 type=""
        //                 icon={<StopFilled />}
        //                 onClick={() => stopCall(record)}
        //             />
        //         </Tooltip>
        //     ),
        //     width: 100,
        //     align: 'center',
        // }
    ];

    const initiateCall = async (record) => {
        if (currentWorkflowId != null && currentWorkflowId != "" && record != null) {
            await triggerCall(currentWorkflowId, record.id);
        }
        else {
            Modal.error({ title: "Error", content: "Unable to initiate the call. Please try again later." });
        }
    }

    const stopCall = (record) => {
        //disconnectCall(currentWorkflowId, record.id);
    }

    const viewConversation = async (record) => {
        let conId = record?.conversation_id;
        if (conId) {
            setLoading(true);
            try {
                let result = await getConvRecordingsAndMessages(conId);
                setConversationData({
                    recordings: JSON.parse(result.recordings),
                    messages: JSON.parse(result.conversationMessages)
                });
                setIsConversationModalVisible(true);
            } catch (error) {
                console.error('Error fetching conversation data:', error);
            } finally {
                setLoading(false);
            }
        }else {
            Modal.error({ title: 'Error', content: 'Conversation ID not found.' });
        }
    }

    console.log(isConversationModalVisible);

    //Initiate the call for a single record
    async function triggerCall(flowId, id) {
        try {
            const inputData = { workflowId: flowId, recordId: id };
            const res = await initCall(inputData);
            if (res) {
                Modal.error({ title: "Error", content: "Call successfully initiated." });
            }
            else {
                const Errormessage = res.data.businessRules[0].description;
                Modal.error({ title: "Error", content: Errormessage });
            }
        } catch (error) {
            console.error('Error in call initiate:', error);
        }
        finally {

        }
    }

    //Disconnect the call for a single record
    async function disconnectCall(flowId, id) {
        try {
            const inputData = { workflowId: flowId, recordId: id };
            const res = await disconnectCall(inputData);
            if (res) {
                Modal.error({ title: "Error", content: "Call successfully disconnected." });
            }
            else {
                const Errormessage = res.data.businessRules[0].description;
                Modal.error({ title: "Error", content: Errormessage });
            }
        } catch (error) {
            console.error('Error in call disconnect:', error);
        }
        finally {

        }
    }

    const handleModalClose = () => {
        setIsModalVisible(false);
        setPatDataSource([]);
        setPatFileName('');
    };

    const handleBacktoFlow = () => {
        setMetaDataSource([]);
        onSendData();
    }


    async function fetchMetaDataByWorkflow(flowId) {
        try {
            setCurrentWorkflowId(flowId);
            const inputData = { workflowId: flowId };
            const res = await fetchMetaData(inputData);
            if (res) {
                setMetaDataSource(res);
                if (res.length > 0) {
                    const columnsFromData = Object.keys(res[0])
                        // .filter(key => !['initiate', 'stop'].includes(key))
                        .map(key => ({
                            title: key.charAt(0).toUpperCase() + key.slice(1),
                            dataIndex: key,
                            key: key,
                            width: 200,
                        }));
                    const metaDatacolumns = [...constantMetaDataColumns, ...columnsFromData];
                    setMetaDataColumns(metaDatacolumns)
                }
            }
            else {
                const Errormessage = res.data.businessRules[0].description;
                Modal.error({ title: "Error in Get Metadata", content: Errormessage });
            }
        } catch (error) {
            console.error('Error in Get Metadata:', error);
        }
    }

    const metaDataAfterUpload = () => {
        setPatDataSource([]);
        setPatFileName('');
        setIsModalVisible(false);

        //Re-Load metaData by workflowId
        fetchMetaDataByWorkflow(currentWorkflowId);
        Modal.success({ title: "Success", content: "Metadata Successfully Uploaded." });
    }

    const handlePatientUpload = async (file) => {
        const fileReader = new FileReader();
        fileReader.onload = async (e) => {
            try {
                const fileData = e.target.result;
                const extension = file.name.split('.').pop().toLowerCase();
                setPatFileName(file.name);

                if (extension === 'xlsx' || extension === 'xls') {
                    const workbook = XLSX.read(fileData, { type: 'binary' });
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                        header: 0,
                        defval: '',
                    });
                    if (jsonData.length > 0) {
                        setPatDataSource(jsonData);
                    }

                } else {
                    throw new Error('Unsupported file type');
                }
            } catch (error) {
                console.error('File processing error:', error);
            } finally {
            }
        };

        fileReader.onerror = () => {
            console.error('File reading error');
        };

        // Trigger file reading based on type
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.type === 'application/vnd.ms-excel') {
            fileReader.readAsBinaryString(file);
        } else {
            console.error('Unsupported file type');
        }
    };


    async function handlePatientUploadSubmit() {
        try {
            if (!patDataSource || patDataSource.length === 0) {
                Modal.error({ title: "Error", content: "Metadata content should not be empty." });
                return;
            }
            if (patDataSource.length > 0) {
                const inputData = { workflowId: currentWorkflowId, data: patDataSource };
                const saveResponse = await saveMetaData(inputData);
                if (saveResponse && saveResponse.config.data) {
                    metaDataAfterUpload(currentWorkflowId);
                }
                else {
                    const Errormessage = saveResponse.data.businessRules[0].description;
                    Modal.error({ title: "Error", content: "Error while upload metadata." });
                }
            }
        } catch (error) {
            console.error('Error while Submit metadata:', error);
        } finally {
        }
    }

    const closeModal = () => {
        setIsConversationModalVisible(false);
    }

    return (
        <div>
            <Card>
                <Row>
                    <Col span={12}>
                        <h3>Metadata</h3>
                    </Col>
                    <Col span={12} align="right">
                        <Button icon={<ArrowLeftOutlined />} onClick={handleBacktoFlow}>  Back to workflow</Button>
                    </Col>
                </Row>

                <Row key={1} gutter={[24]} align="middle">
                    <Col span={7} className='form-group'>
                        <Button icon={<UserAddOutlined />} disabled={loading} onClick={setIsModalVisible}> Upload Metadata</Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        {loading ? (
                            <Spin tip="Loading" size="large"></Spin>
                        ) : (
                            <Table
                                className='cus-table'
                                scroll={{ x: 2000, y: 150 }}
                                dataSource={metaDataSource}
                                columns={metaDataColumns}
                                rowKey="id"
                                pagination={true}
                                style={{ marginTop: 20 }}
                            />
                        )}
                    </Col></Row>
                <Modal
                    title="Upload Metadata"
                    width={1000}
                    open={isModalVisible}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <Row key={1} gutter={[24]} align="middle">
                        <Col span={7} className='form-group'>
                            <Upload beforeUpload={handlePatientUpload} showUploadList={false} accept=".csv,.xlsx,.xls">
                                <Button icon={<UploadOutlined />} disabled={loading}>
                                    Upload Metadata Excel or CSV
                                </Button>
                            </Upload>
                        </Col>

                        <Col span={6} className='form-group'>
                            {patFileName && (
                                <div style={{ marginTop: '8px' }}>
                                    <label>Selected File : {patFileName}</label>
                                </div>
                            )}
                        </Col>

                        <Col span={6} className='form-group'>
                            <Button type="primary" htmlType="submit" onClick={handlePatientUploadSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {patDataSource.length > 0 && (
                                <Table
                                    scroll={{ x: 2000, y: 150 }}
                                    dataSource={patDataSource}
                                    columns={Object.keys(patDataSource[0]).map(key => ({
                                        title: key,
                                        dataIndex: key,
                                        key,
                                    }))}
                                    rowKey={(record, index) => index}
                                    pagination={false}
                                    style={{ marginTop: 20 }}
                                />
                            )}
                        </Col></Row>
                </Modal>
                
            </Card>
            <ConversationModal 
                isModalOpen ={isConversationModalVisible} 
                onCloseModal ={closeModal} 
                conversationRecording ={conversationData.recordings}
                loadingConversation ={loading} 
                conversationMessages={conversationData.messages} 
            />
        </div>
    );
};

export default WorkflowMetadata;
