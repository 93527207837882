import React, { useState, useEffect } from 'react';
import { Tabs, Form, Input, Select, Button, Table, Space, Popconfirm, message, Col } from 'antd';
import { DeleteOutlined, CloseCircleOutlined,EditOutlined } from '@ant-design/icons';
import { metaDataLoad } from '../../../services/admin/adminService';
import { fetchBranchInitialData, saveStoredProcedure, DeleteStoreProcedure } from '../../../services/componentService.js';

const { TabPane } = Tabs;
const { Option } = Select;

const Configuration = () => {
    const [apiForm] = Form.useForm();
    const [spForm] = Form.useForm();
    const [currentEditingApi, setCurrentEditingApi] = useState(null);
    const [apiData, setApiData] = useState([]);
    const [spData, setSpData] = useState([]);
    const [parameterOptions, setParameterOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [apiSearchText, setApiSearchText] = useState('');
    const [spSearchText, setSpSearchText] = useState('');

    // Fetch metadata for the parameter dropdown
    useEffect(() => {
        const fetchPopulateData = async () => {
            setLoading(true);
            try {
                const response = await metaDataLoad();
                if (response !== undefined) {
                    const parameters = response.map(param => ({
                        value: param.parameter_name,
                        label: param.parameter_name,
                    }));
                    setParameterOptions(parameters);
                }
            } catch (error) {
                message.error('Error fetching parameter data');
                console.error('Error fetching parameter data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPopulateData();
    }, []);

    // Fetch API and SP data
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchBranchInitialData();
                if (response?.data) {
                    const apiFunctions = response.data.functionName.map(func => ({
                        key: func.functionCallListId,
                        name: func.apiFunctionName,
                        params: '',
                    }));
                    setApiData(apiFunctions);

                    const spFunctions = response.data.spList.map(func => ({
                        key: func.storeProcedureListId,
                        name: func.list,
                        params: '',
                    }));
                    setSpData(spFunctions);
                }
            } catch (error) {
                message.error('Error fetching data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleAddAPI = () => {
        apiForm.validateFields().then((values) => {
            const apiParams = Array.isArray(values.apiParams) ? values.apiParams : [];
            const newData = {
                key: apiData.length + 1,
                name: values.apiName,
                params: apiParams,
            };
            console.log("New API Data:", newData);
            setApiData([...apiData, newData]);
            apiForm.resetFields();
        }).catch((error) => {
            message.error('Please fill in all required fields!');
            console.error('Validation error:', error);
        });
    };

    const handleAddSP = async () => {
        try {
            const values = await spForm.validateFields(['spName']);
            const storeProcedureName = values.spName;
            if (storeProcedureName != "" && storeProcedureName != undefined) {
                const response = await saveStoredProcedure({ storeProcedureName });
                if (response.success && response.data && response.data.isExists === 'false') {
                    message.success('Stored procedure added successfully.');
                    const updatedResponse = await fetchBranchInitialData();
                    if (updatedResponse?.data) {
                        const spFunctions = updatedResponse.data.spList.map(func => ({
                            key: func.storeProcedureListId,
                            name: func.list,
                            params: '',
                        }));
                        setSpData(spFunctions);
                    }
                    spForm.resetFields();
                } else {
                    message.error('Failed to add stored procedure. It may already exist.');
                }
            }
        } catch (error) {
            message.error('Please input SP name!');
        }
    };

    const handleSaveEdit = () => {
        apiForm.validateFields().then((values) => {
            const updatedApiData = apiData.map(item => {
                if (item.key === currentEditingApi.key) {
                    return { ...item, name: values.apiName, params: values.apiParams };
                }
                return item;
            });
            setApiData(updatedApiData);
            setCurrentEditingApi(null);  // Reset editing state after saving
            apiForm.resetFields();
            message.success('API updated successfully!');
        }).catch((error) => {
            message.error('Please fill in all required fields!');
            console.error('Validation error:', error);
        });
    };
    const handleEdit = (record) => {
        setCurrentEditingApi(record);
        apiForm.setFieldsValue({
            apiName: record.name,
            apiParams: record.params
        });
    };
    const handleDelete = (key, type) => {
        if (type === 'api') {
            setApiData(apiData.filter(item => item.key !== key));
        } else {
            setSpData(spData.filter(item => item.key !== key));
        }
    };

    const handleSpDelete = async (storeProcedureListId) => {
        try {
            const response = await DeleteStoreProcedure(storeProcedureListId);
            if (response.success) {
                spForm.resetFields();
                message.success('Stored procedure deleted successfully');
                setSpData(prevData => prevData.filter(item => item.key !== storeProcedureListId));
            } else {
                message.error('Failed to delete stored procedure');
            }
        } catch (error) {
            message.error('Error deleting stored procedure');
            console.error('Error deleting stored procedure:', error);
        }
    };

    const handleApiSearchClear = () => setApiSearchText('');
    const handleSpSearchClear = () => setSpSearchText('');

    const filteredApiData = apiData.filter(item => item.name.toLowerCase().includes(apiSearchText.toLowerCase()));
    const filteredSpData = spData.filter(item => item.name.toLowerCase().includes(spSearchText.toLowerCase()));

    const apiColumns = [
        { title: 'API Name', dataIndex: 'name', key: 'name' },
       // { title: 'Parameters', dataIndex: 'params', key: 'params', },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                       <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                    <Popconfirm title="Are you sure you want to delete this record?" okText="Yes" cancelText="No" onConfirm={() => handleDelete(record.key, 'api')}>
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const spColumns = [
        { title: 'SP Name', dataIndex: 'name', key: 'name' },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Popconfirm title="Are you sure you want to delete this record?" okText="Yes" cancelText="No" onConfirm={() => handleSpDelete(record.key)}>
                        <Button icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <h2 style={{ marginBottom: '7px' }}>Configuration</h2>
            <Tabs defaultActiveKey="1">
           
            <TabPane tab="API Configuration" key="1">
                <Form form={apiForm} layout="inline">
                    <Form.Item
                        name="apiName"
                        label="API Name"
                        rules={[{ required: true, message: 'Please input API name!' }]}
                    >
                        <Input   style={{ width: '200px' }} placeholder="Enter API name" />
                    </Form.Item>
                    <Form.Item
                        name="apiParams"
                        label="Parameters"
                        rules={[{ required: true, message: 'Please select parameters!' }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select parameters"
                            style={{ width: '300px' }}
                            maxTagCount={1}
                            maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
                            loading={loading}
                        >
                            {parameterOptions.map((param) => (
                                <Option key={param.value} value={param.value}>
                                    {param.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {currentEditingApi ? (
                            <Button type="primary" onClick={handleSaveEdit}>
                                Update API
                            </Button>
                        ) : (
                            <Button type="primary" onClick={handleAddAPI}>
                                Add API
                            </Button>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Input
                            className='cus-antd-white'
                            placeholder="Search API name"
                            value={apiSearchText}
                            onChange={e => setApiSearchText(e.target.value)}
                            style={{ width: 240 }}
                        />
                        <Button type='text' shape='default' onClick={handleApiSearchClear}>
                            <CloseCircleOutlined />
                        </Button>
                    </Form.Item>
                </Form>
                <br />
                <Table
                    className='cus-antd-white'
                    bordered={true}
                    columns={apiColumns}
                    dataSource={filteredApiData}
                    pagination={{ pageSize: 9, showSizeChanger: false }}
                />
            </TabPane>
        

                <TabPane tab="SP Configuration" key="2">
                    <Form form={spForm} layout="inline">
                        <Form.Item
                            name="spName"
                            label="SP Name"
                            rules={[{ required: true, message: 'Please input SP name!' }]}
                        >
                            <Input placeholder="Enter SP name" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={handleAddSP}>
                                Add SP
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Input
                                className='cus-antd-white'
                                placeholder="Search SP name"
                                value={spSearchText}
                                onChange={e => setSpSearchText(e.target.value)}
                                style={{ width: 240 }}
                            />

                            <Button type='text' shape='default' onClick={handleSpSearchClear}>
                                <CloseCircleOutlined />
                            </Button>
                        </Form.Item>
                    </Form>
                    <br />



                    <Table
                        className='cus-antd-white'
                        bordered={true}
                        columns={spColumns}
                        dataSource={filteredSpData}
                        pagination={{ pageSize: 9, showSizeChanger: false }}
                    />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Configuration;
