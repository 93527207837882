import React from 'react';
import { useRoutes } from "react-router-dom";

/* Components Pages */
import BranchComponent from './pages/components/branch/branchComponent';
import ApiCall from './pages/components/branch/apiCall';
import SendMessage from './pages/components/branch/sendMessage';

import OutBound from './pages/components/outbound/outBound';
import InBound from './pages/components/inbound/inBound';
import InboundAnalyze from './pages/components/inboundanalyze/inBoundAnalyze';


/* Admin Pages */
import PrivateRoute from './pages/admin/PrivateRoute';
import LoginMasterLayout from './pages/admin/layout/LoginMasterLayout';
import Login from './pages/admin/pages/login';

import DashboardLayout from './pages/admin/layout/DashboardLayout';
import Dashboard from './pages/admin/pages/dashboard';
import PossibleResponseGroup from './pages/admin/pages/PossibleResponseGroup';
import Configuration from './pages/admin/pages/configuration';
import MetaDataGroup from './pages/admin/pages/metaDataGroup';
import WorkFlowComponent from './pages/components/workflowcomponent/workFlowComponent';
import WorkFlow from './pages/components/workflowcomponent/workflow';
import DemoComp from './pages/components/branch/demoComp';
import UploadWorkflow from './pages/components/uploadWorkflow/uploadWorkflow';
import { Upload } from 'antd';


export default function App() {
    const isAuth = true;

    const routes = useRoutes([
        //Workflow Components Routes
        {
            path: "/branchcomponent",
            element: <BranchComponent />
        },
        {
            path: "/apiCall",
            element: <ApiCall />
        },
        {
            path: "/sendMessage",
            element: <SendMessage />
        },
        {
            path: "/inBound",
            element: <InBound />
        },
        {
            path: "/outBound",
            element: <OutBound />
        },
        {
            path: "/inBoundAnalyze",
            element: <InboundAnalyze />
        },
        {
            path: "/demoComp",
            element: <DemoComp />
        },


        //Admin Routes
        {
            path: "/",
            element: <LoginMasterLayout><Login /></LoginMasterLayout>
        },
        {
            path: "/dashboard",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><Dashboard /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/PossibleResponseGroup",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><PossibleResponseGroup /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/Configuration",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><Configuration/></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/MetaDataGroup",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><MetaDataGroup /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/WorkFlowComponent",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><WorkFlowComponent /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/Workflow",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><WorkFlow /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "/UploadWorkflow",
            element: <PrivateRoute isAuth={isAuth}>
                <DashboardLayout><UploadWorkflow /></DashboardLayout>
            </PrivateRoute>
        },
        {
            path: "*",
            element: <LoginMasterLayout><Login /></LoginMasterLayout>
        }
    ])
    return routes;
}
