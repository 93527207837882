import axios from 'axios';
import axiosInterceptor from "../middleware/axiosInterceptor";
const baseURL = process.env.REACT_APP_BASE_API_URI;

export const fetchWorkflowData = async () => {
    const url = "/api/workflows";
    try {
        const response = await axiosInterceptor.get(url);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const saveMetaData = async (PatientJson) => {
    try {
        const url = process.env.REACT_APP_BOT_API_URI + `bot-v2/addMetaData`;
        const response = await axios.post(url, PatientJson);
        return response;
    }
    catch (error) {
        console.log("Error fetching workflow component:", error);
        throw error;
    }
};

export const fetchMetaData = async (workflowId) => {
    const url = process.env.REACT_APP_BOT_API_URI + `bot-v2/getMetaData`;
    try {
        const response = await axiosInterceptor.post(url, workflowId);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const initCall = async (data) => {
    const url = process.env.REACT_APP_BOT_API_URI + `bot-v2/initCall`;
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const disconnectCall = async (data) => {
    const url = process.env.REACT_APP_BOT_API_URI + `bot-v2/disconnectcall`;
    try {
        const response = await axiosInterceptor.post(url, data);
        return response.data;
    }
    catch (error) {
        console.error(error);
    }
};

export const getConvRecordingsAndMessages = async (conId) => {
    const url = process.env.REACT_APP_BASE_API_URI + `api/getConversationInfo?cid=${conId}`;
    console.log(url);
    try {
        const response = await axiosInterceptor.get(url);
        return response.data.data;
    }
    catch (error) {
        console.error(error);
    }
};