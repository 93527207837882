import React, { useState, useEffect } from 'react';
import { Upload, Button, message, Form, Input, Tabs, Modal, Row, Col, Table, Card, Tooltip } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined, ArrowLeftOutlined, UserAddOutlined, PhoneOutlined, StopOutlined, PhoneFilled, StopFilled, EyeOutlined } from '@ant-design/icons';
import { convertJsonArray, savePatientData, saveWorkflow, updateMasterTable, UploadFile } from '../../../services/componentService.js';
import { fetchWorkflowData, fetchMetaData, saveMetaData, initCall, disconnectCall } from '../../../services/workFlowService.js';
import * as XLSX from 'xlsx';
import WorkflowMetadata from './workflowMetadata';

const { TabPane } = Tabs;
const UploadWorkflow = () => {
  const [workflowForm] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [patDataSource, setPatDataSource] = useState([]);
  const [flowDiagram, setflowDiagram] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [patFileName, setPatFileName] = useState('');

  const baseURL = process.env.REACT_APP_BASE_API_URI;
  const [workflowsData, setWorkflowsData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isMetaDataVisible, setMetaDataVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
  const [metaDataSource, setMetaDataSource] = useState([]);
  const [metaDataColumns, setMetaDataColumns] = useState([]);

  const workFlowColumns = [
    { title: 'id', dataIndex: 'workFlowId', key: 'workFlowId', hidden: true, width: 100, },
    { title: 'WorkFlow Name', dataIndex: 'workFlowName', key: 'workFlowName', width: 100 },
    { title: 'Created Date', dataIndex: 'createdDate', key: 'createdDate', width: 100 },
    { title: 'Created User', dataIndex: 'createdUser', key: 'createdUser', width: 100 },
    {
      title: 'Actions', width: 60, key: 'actions', align: 'center', render: (text, record) =>
      (<> <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} onClick={() => editWorkflow(record.workFlowId)}> <EyeOutlined className='text-color-black' /> </Button>
        {/* <Button style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }} danger onClick={() => deleteWorkflow(record.workFlowId)}> <DeleteOutlined /> </Button> 
         */}
      </>
      ),
    },
    {
      title: 'Meta Data',
      key: 'addMetaData',
      width: 25,
      align: 'center',
      render: (text, record) => (
        <Button
          style={{ width: 25, border: '0', background: 'none', color: '#fff', padding: 0 }}
          onClick={() => handleUploadClick(record)}
        >
          <UploadOutlined />
        </Button>
      ),
    },
  ];

  // const constantMetaDataColumns = [
  //   {
  //     title: 'Initiate Call',
  //     dataIndex: 'initiate',
  //     key: 'initiate',
  //     render: (text, record) => (
  //       <Tooltip title="Initiate Call">
  //         <Button
  //           type="primary"
  //           icon={<PhoneFilled />}
  //           onClick={() => initiateCall(record)}
  //         />
  //       </Tooltip>
  //     ),
  //   },
  //   {
  //     title: 'Stop',
  //     dataIndex: 'stop',
  //     key: 'stop',
  //     render: (text, record) => (
  //       <Tooltip title="Stop Call">
  //         <Button
  //           type="primary"
  //           icon={<StopFilled />}
  //           onClick={() => stopCall(record)}
  //         />
  //       </Tooltip>
  //     ),
  //   }
  // ];

  useEffect(() => {
    //Load List of workflows
    async function fetchFlowData() {
      try {
        const response = await fetchWorkflowData();
        if (response.workflow !== undefined) {
          setWorkflowsData(response.workflow[0]);
        }
      } catch (error) {
        console.error('Error fetching location data:', error);
      } finally {
      }
    }
    fetchFlowData();
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
      // console.log("newSelectedRowKeys:", newSelectedRowKeys);
      // setCurrentWorkflowId(newSelectedRowKeys);
    },
  };

  const editWorkflow = (flowId) => {
    window.location.href = baseURL + "workflow/" + flowId;
  }

  const deleteWorkflow = (flowId) => {
    window.location.href = baseURL + "workflow/" + flowId;
  }

  const handleUploadClick = async (record) => {
    setCurrentWorkflowId(record.workFlowId);
    setMetaDataVisible(true);
    // //Load metaData by workflowId
    // await fetchMetaDataByWorkflow(record.workFlowId);
  };

  const handleDataFromChild = () => {
    setMetaDataVisible(false);
  };

  // const initiateCall = async (record) => {
  //   console.log("currentWorkflowId:", currentWorkflowId);
  //   // if (currentWorkflowId != null && currentWorkflowId != "" && record != null) {
  //   //   //await triggerCall(currentWorkflowId, record.id);
  //   // }
  //   // else {
  //   //   Modal.error({ title: "Error", content: "Unable to initiate the call. Please try again later." });
  //   // }
  // }

  // const stopCall = (record) => {
  //   //disconnectCall(currentWorkflowId, record.id);
  // }

  // const rowMetaDataSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {

  //   },
  // };

  //Initiate the call for a single record
  // async function triggerCall(flowId, id) {
  //   try {
  //     const inputData = { workflowId: flowId, recordId: id };
  //     console.log("inputData", inputData);
  //     const res = await initCall(inputData);
  //     if (res) {
  //       Modal.error({ title: "Error", content: "Call successfully initiated." });
  //     }
  //     else {
  //       const Errormessage = res.data.businessRules[0].description;
  //       Modal.error({ title: "Error", content: Errormessage });
  //     }
  //   } catch (error) {
  //     console.error('Error in call initiate:', error);
  //   }
  //   finally {

  //   }
  // }

  // //Disconnect the call for a single record
  // async function disconnectCall(flowId, id) {
  //   try {
  //     const inputData = { workflowId: flowId, recordId: id };
  //     const res = await disconnectCall(inputData);
  //     if (res) {
  //       Modal.error({ title: "Error", content: "Call successfully disconnected." });
  //     }
  //     else {
  //       const Errormessage = res.data.businessRules[0].description;
  //       Modal.error({ title: "Error", content: Errormessage });
  //     }
  //   } catch (error) {
  //     console.error('Error in call disconnect:', error);
  //   }
  //   finally {

  //   }
  // }

  // async function fetchMetaDataByWorkflow(flowId) {
  //   try {
  //     setCurrentWorkflowId(flowId);
  //     const inputData = { workflowId: flowId };
  //     const res = await fetchMetaData(inputData);
  //     if (res) {
  //       setMetaDataSource(res);
  //       if (res.length > 0) {
  //         const columnsFromData = Object.keys(res[0])
  //           // .filter(key => !['initiate', 'stop'].includes(key))
  //           .map(key => ({
  //             title: key.charAt(0).toUpperCase() + key.slice(1),
  //             dataIndex: key,
  //             key: key,
  //           }));
  //         const metaDatacolumns = [...constantMetaDataColumns, ...columnsFromData];
  //         setMetaDataColumns(metaDatacolumns)
  //       }
  //     }
  //     else {
  //       const Errormessage = res.data.businessRules[0].description;
  //       Modal.error({ title: "Error in Get Metadata", content: Errormessage });
  //     }
  //   } catch (error) {
  //     console.error('Error in Get Metadata:', error);
  //   }
  // }

  // const handleModalClose = () => {
  //   setIsModalVisible(false);
  //   setPatDataSource([]);
  //   setPatFileName('');
  // };

  // const handleBacktoFlow = () => {
  //   setMetaDataVisible(false);
  //   setMetaDataSource([]);
  // }

  const handleUpload = (file) => {
    setLoading(true);  // Start loader
    const fileReader = new FileReader();
    fileReader.onload = (e) => {

      const fileData = e.target.result;
      const extension = file.name.split('.').pop().toLowerCase();
      setFileName(file.name);

      if (extension === 'xlsx' || extension === 'xls') {
        // Parse Excel file
        const workbook = XLSX.read(fileData, { type: 'binary' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          header: 0,
          defval: '',
        });
        if (jsonData.length > 0) {
          setDataSource(jsonData);
        }
        setLoading(false);  // Stop loader
      }
    };

    fileReader.onerror = () => {
      setLoading(false);  // Stop loader on error
    };

    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel') {
      fileReader.readAsBinaryString(file);
    } else {
      fileReader.readAsText(file);
    }
    return false;
  };

  async function handleSubmit() {
    setLoading(true);
    try {
      const flowName = workflowForm.getFieldValue('name');

      if (!flowName || flowName.length === 0) {
        Modal.error({ title: "Save workflow Error", content: "Workflow name should not be empty" });
        return;
      }

      if (!dataSource || dataSource.length === 0) {
        Modal.error({ title: "Save workflow Error", content: "Please select a workflow file" });
        return;
      }

      const response = await convertJsonArray(dataSource);
      if (response && response.data && response.data.success) {
        setflowDiagram(response.data)
        const jsonData = {
          workFlowName: flowName,
          workflowId: 0,
          workFlowDiagram: JSON.stringify(response.data.Data),
          workFlowData: JSON.stringify(response.data.StepJson.nodeDataArray)
        };

        const Saveresponse = await saveWorkflow(jsonData);
        if (Saveresponse && Saveresponse.data.error != null && Saveresponse.data.error != "") {
          const Errormessage = Saveresponse.data.error.value;
          Modal.error({ title: "Error Save workflow", content: Errormessage });
        }
        else {
          if (Saveresponse && Saveresponse.data.result != null && Saveresponse.data.workflowId != "") {
            const workflowId = Saveresponse.data.result.workflowId;

            const updatejson = {
              workFlowName: flowName,
              workflowId: workflowId
            };

            const Updateresponse = await updateMasterTable(updatejson);
            if (Updateresponse && Updateresponse.data.error != null && Updateresponse.data.error != "") {
              const Errormessage = Updateresponse.data.error.value;
              Modal.error({ title: "Update Master Table", content: Errormessage });
            }

            clearForm();
            Modal.success({ title: "Success", content: "Workflow Saved Successfully." });
          }
        }
      }
      else {
        const Errormessage = response.data.businessRules[0].description;
        Modal.error({ title: "Error excel to workflow json conversion", content: Errormessage });
      }
    } catch (error) {
      console.error('Error Submit workflow data:', error);
    } finally {
    }
    setLoading(false);
  };

  // const handlePatientUpload = async (file) => {
  //   setLoading(true);  // Start loader
  //   const fileReader = new FileReader();
  //   fileReader.onload = async (e) => {
  //     try {
  //       const fileData = e.target.result;
  //       const extension = file.name.split('.').pop().toLowerCase();
  //       setPatFileName(file.name);

  //       if (extension === 'xlsx' || extension === 'xls') {
  //         const workbook = XLSX.read(fileData, { type: 'binary' });
  //         const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  //         console.log("worksheet:", worksheet);
  //         const jsonData = XLSX.utils.sheet_to_json(worksheet, {
  //           header: 0,
  //           defval: '',
  //         });
  //         console.log("jsonData:", jsonData);
  //         if (jsonData.length > 0) {
  //           setPatDataSource(jsonData);
  //         }
  //         setLoading(false);  // Stop loader

  //       } else {
  //         throw new Error('Unsupported file type');
  //       }
  //     } catch (error) {
  //       console.error('File processing error:', error);
  //     } finally {
  //       setLoading(false);  // Stop loader
  //     }
  //   };

  //   fileReader.onerror = () => {
  //     console.error('File reading error');
  //     setLoading(false);  // Stop loader on error
  //   };

  //   // Trigger file reading based on type
  //   if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
  //     file.type === 'application/vnd.ms-excel') {
  //     fileReader.readAsBinaryString(file);
  //   } else {
  //     console.error('Unsupported file type');
  //     setLoading(false);  // Stop loader for unsupported files
  //   }
  // };

  const clearForm = async () => {
    workflowForm.resetFields();
    setFileName('');
    setPatFileName('');
    setLoading(false);
    setDataSource([]);
    setPatDataSource([]);
    setflowDiagram([]);
  };

  // async function handlePatientUploadSubmit() {
  //   setLoading(true);
  //   try {
  //     if (!patDataSource || patDataSource.length === 0) {
  //       Modal.error({ title: "Error Save Metadata", content: "Metadata Content Shoud not empty" });
  //       return;
  //     }
  //     if (patDataSource.length > 0) {
  //       const inputData = { workflowId: currentWorkflowId, data: patDataSource };
  //       console.log("inputData:", inputData);
  //       const saveResponse = await saveMetaData(inputData);
  //       if (saveResponse && saveResponse.config.data) {
  //         setPatDataSource([]);
  //         setPatFileName('');
  //         //Re-Load metaData by workflowId
  //         fetchMetaDataByWorkflow(currentWorkflowId);
  //         Modal.success({ title: "Success", content: "Metadata Successfully Uploaded." });
  //       }
  //       else {
  //         const Errormessage = saveResponse.data.businessRules[0].description;
  //         Modal.error({ title: "Error in Metadata Upload", content: Errormessage });
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error Submit workflow data:', error);
  //   } finally {
  //   }
  //   setLoading(false);

  // }

  return (
    <div>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="WorkFlow Generate" key="1">
          {!isMetaDataVisible &&
            (
              <>
                <Row key={1} gutter={[24]} align="middle">

                  <Col span={7} className='form-group'>
                    <Form form={workflowForm} layout="inline">
                      <Form.Item name="name" label="WorkFlow Name" rules={[{ required: true, message: 'Please input WorkFlow name!' }]}>
                        <Input style={{ width: '200px' }} placeholder="Enter WorkFlow Name" />
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col span={5} className='form-group'>
                    <Upload beforeUpload={handleUpload} showUploadList={false} accept=".csv,.xlsx,.xls">
                      <Button icon={<UploadOutlined />} disabled={loading}>
                        Upload Workflow Excel or CSV
                      </Button>
                    </Upload>
                  </Col>

                  <Col span={5} className='form-group'>
                    {fileName && (
                      <div style={{ marginTop: '8px' }}>
                        <label>Selected File : {fileName}</label>
                      </div>
                    )}
                  </Col>

                  <Col span={6} className='form-group'>
                    <Button type="primary" htmlType="submit" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </Col>

                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Table size='small' scroll={{ x: 'max-content', y: 500 }} pagination={false} rowSelection={rowSelection} className='cus-table' columns={workFlowColumns} dataSource={workflowsData} rowKey="workFlowId" />
                  </Col>
                </Row>
              </>
            )}
          {isMetaDataVisible &&
            (
              <WorkflowMetadata workflowId={currentWorkflowId} onSendData={handleDataFromChild} />
              // <Card>
              //   <Row>
              //     <Col span={12}>
              //       <h3>Metadata</h3>
              //     </Col>
              //     <Col span={12} align="right">
              //       <Button icon={<ArrowLeftOutlined />} onClick={handleBacktoFlow}>  Back to workflow</Button>
              //     </Col>
              //   </Row>

              //   <Row key={1} gutter={[24]} align="middle">
              //     <Col span={7} className='form-group'>
              //       <Button icon={<UserAddOutlined />} disabled={loading} onClick={setIsModalVisible}> Upload Metadata</Button>
              //     </Col>
              //   </Row>
              //   <Row>
              //     <Col span={24}>

              //       <Table
              //         scroll={{ x: 2000, y: 150 }}
              //         dataSource={metaDataSource}
              //         columns={metaDataColumns}
              //         rowKey="id"
              //         pagination={true}
              //         style={{ marginTop: 20 }}
              //         rowSelection={rowMetaDataSelection}
              //       />

              //     </Col></Row>
              //   <Modal
              //     title="Upload Metadata"
              //     width={1000}
              //     open={isModalVisible}
              //     onCancel={handleModalClose}
              //     footer={null}
              //   >
              //     <Row key={1} gutter={[24]} align="middle">
              //       <Col span={7} className='form-group'>
              //         <Upload beforeUpload={handlePatientUpload} showUploadList={false} accept=".csv,.xlsx,.xls">
              //           <Button icon={<UploadOutlined />} disabled={loading}>
              //             Upload Metadata Excel or CSV
              //           </Button>
              //         </Upload>
              //       </Col>

              //       <Col span={6} className='form-group'>
              //         {patFileName && (
              //           <div style={{ marginTop: '8px' }}>
              //             <label>Selected File : {patFileName}</label>
              //           </div>
              //         )}
              //       </Col>

              //       <Col span={6} className='form-group'>
              //         <Button type="primary" htmlType="submit" onClick={handlePatientUploadSubmit}>
              //           Submit
              //         </Button>
              //       </Col>
              //     </Row>
              //     <Row>
              //       <Col span={24}>
              //         {patDataSource.length > 0 && (
              //           <Table
              //             scroll={{ x: 2000, y: 150 }}
              //             dataSource={patDataSource}
              //             columns={Object.keys(patDataSource[0]).map(key => ({
              //               title: key,
              //               dataIndex: key,
              //               key,
              //             }))}
              //             rowKey={(record, index) => index}
              //             pagination={false}
              //             style={{ marginTop: 20 }}
              //           />
              //         )}
              //       </Col></Row>
              //   </Modal>
              // </Card>
            )}

        </TabPane>

        {/* <TabPane tab="Patient Data" key="2">
          <Row key={1} gutter={[24]} align="middle">
            <Col span={8} className='form-group'>
              <span tabIndex="0">Patient Data  </span>
              <Upload beforeUpload={handlePatientUpload} showUploadList={false} accept=".csv,.xlsx,.xls">
                <Button icon={<UploadOutlined />} disabled={loading}>
                  Upload Excel or CSV
                </Button>
              </Upload>
            </Col>

            <Col span={6} className='form-group'>
              {patFileName && (
                <div style={{ marginTop: '8px' }}>
                  <label>Selected File : {patFileName}</label>
                </div>
              )}
            </Col>

            <Col span={6} className='form-group'>
              <Button type="primary" htmlType="submit" style={{ marginTop: 16 }} onClick={handlePatientUploadSubmit}>
                Submit
              </Button>
            </Col>


          </Row>
        </TabPane> */}
      </Tabs>
    </div>
  );
};

export default UploadWorkflow;
